import React, {useEffect, useState} from "react";
import { Link,useNavigate } from 'react-router-dom';
import DataTable from "react-data-table-component";


const Contactlist = () =>{
  const [userdata,setData]=useState ([]);
  const [fitleruserdata,setfilterData]=useState ([]);
  const[search,setsearch]=useState("");

  //login session
  const history = useNavigate();
  var nameuser = localStorage.getItem("loginnamelsset");

  useEffect(() => {  
    console.log("wwwww"); 
    console.log(nameuser);       
    // if(nameuser === null){
    //        history('/contact')
    //  }
    if(!nameuser){
      history('/contact')
      }
    }, []);  
  
    //login session
  
  useEffect(()=>{  
   // console.log("heeloo hhhh");
    


   fetch("https://howcanfind.com/reactapi/listcoursenew.php").then((result)=>{
    result.json().then((resp)=>{
        // console.warn("result",resp)
         console.log(resp) 
         setData(resp.data)
         setfilterData(resp.data)
       })
})

},[]);

   //console.log(userdata);

   useEffect(()=>{  
const result=userdata.filter(product=>{
  return product.product_name.toLowerCase().match(search.toLowerCase());
});

setfilterData(result);
 
 },[search]);


   const columns=[
    {
      name:"Id Name",
      selector: (row)=>row.id,
    },
    {
      name:"User Name",
      selector: (row)=>row.product_name,
      sortable:true,
    },
    {
      name:"Password Cost",
      selector: (row)=>row.cost,      
    },
    {
      name:"Edit",
      cell: (row)=> <Link to={`/userlist/EditUser/${row.id}`}>Edit</Link>,
    },
  ];

  // login session reload
  function refreshPage(){ 
    window.location.reload(); 
      }

  return (
  <div>
    

          <div className="container" style={{ textAlign:"center"}}>
                  <h1 className="py-4"  >User List </h1>
                  <div>
                    <div className="col-lg-6"></div>
                  <div className="col-lg-6"> 

                  <a href={'/userlist/AddUser'} >Adduser</a> |  
                  <button refresh="true" onClick={()=> {
                localStorage.removeItem("loginnamelsset");
                refreshPage();
              }} 
        className="btn btn-primary btn-sm">logout</button> 



              </div> 
                    </div>
                    </div>
    
            <DataTable columns={columns} className="table table-striped"
            data={fitleruserdata} pagination fixedHeader fixedHeaderScrollHeight="850px" 
            highlightOnHover selectableRows selectableRowsHighlight 
            actions={<button className="btn btn-info">Export</button>} 
            subHeader
            subHeaderComponent={
              <input type="text" className="w-25 form-control" placeholder="search"
              name = "search"
              value={search}          
              onChange={(e)=>{setsearch(e.target.value)}}
              />
            }    
            />
      </div>   
  );
};
export default Contactlist;
