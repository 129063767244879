import React,{useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
//import './App.css';
const About=()=>{
//function Home() {

// login session
  const history = useNavigate();
  var nameuser = localStorage.getItem("loginnamelsset");

  useEffect(() => {  
    console.log("wwwww"); 
    console.log(nameuser);       
    // if(nameuser === null){
    //        history('/contact')
    //  }
    if(!nameuser){
      history('/contact')
      }
    }, []);
    // login session

    function refreshPage(){ 
      window.location.reload(); 
        }


  return (
    <div className="container">
      <h1> About US |  <button refresh="true" onClick={()=> {
        localStorage.removeItem("loginnamelsset");
        refreshPage();
      }} 
className="btn btn-primary btn-sm">logout</button>  </h1> 
      <h1>User Nme : {nameuser} </h1> 

      

      <p> 


      This system makes sure that the enquiries are carefully registered, tracked, and handled so that 
      sales teams can respond. The Call Management System is a solution that was created to solve the
       problems that many organizations have in keeping track of their call inquiries or leads
        from their client. 
      The fundamental issue is how these businesses handle these call inquiries in real-time
      </p>
    </div>
  );
}

export default About;
