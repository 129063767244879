import React,{useEffect, useState} from 'react';
//import axios from 'axios';
import { useNavigate } from 'react-router-dom';
 
//import './App.css';
const AddUser=()=>{
//function Home() {  

        //login session
        const history = useNavigate();
        var nameuser = localStorage.getItem("loginnamelsset");

        useEffect(() => {  
          console.log("wwwww"); 
          console.log(nameuser);       
          // if(nameuser === null){
          //        history('/contact')
          //  }
          if(!nameuser){
            history('/contact')
            }
          }, []);  

          //login session
  
    //const history = useNavigate();
    const[titlecourse,settitlecourse]=useState("");
    const[listshow,setlistshow]=useState("");
    //console.log(titlecourse);

    const onSubmit = async (event) => {
        event.preventDefault();
        console.log(titlecourse);
        //const data = this.state.titlecourse;
        //const dataq = 11;

        //https://howcanfind.com/reactapi/addlistcourse.php

        await fetch("https://howcanfind.com/reactapi/addlistcourse.php",{
            method: 'POST',
            headers: new Headers({
                       'Content-Type': 'application/x-www-form-urlencoded',
              }),
              body: new URLSearchParams({
                titlecourse:titlecourse,
                //listshow:'tt'
                listshow:listshow
              })
             // right= body: "titlecourse=sassa&listshow=jhjhg"
           // right= body: "titlecourse= " + data
          // body:{
            //   "titlecourse":this.state.titlecourse             
           //}

      
        }).then((res)=>console.log(res));

        // after submit emtey
        settitlecourse("")
        setlistshow("")
        history('/contactlist')

    }



  return (

    <div>

    <section className="bg-light py-3 py-md-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
            <div className="card border border-light-subtle rounded-3 shadow-sm">
              <div className="card-body p-3 p-md-4 p-xl-5">
                

                     
                      <h1>Add User</h1>
                      <form onSubmit={e=>onSubmit(e)}>
                  <div class="row mb-3">
                   
                    <div class="col-sm-10">
                      <input type="text" class="form-control"
                      name = "titlecourse" placeholder="Add User name"
                      value={titlecourse}
                      onChange={(e)=>{settitlecourse(e.target.value)}}
                    />
                    </div>
                  </div>
                  <div class="row mb-3">                    
                    <div class="col-sm-10">
                      <input type="text" class="form-control"
                      name = "listshow" placeholder="Add Password"
                      value={listshow}      
                      onChange={(e)=>{setlistshow(e.target.value)}}
                      />
                    </div>
                  </div>
                  
                  
                  <button type="submit" class="btn btn-primary">Add User</button>
                </form>

                     


              </div>
              </div>
              </div>
              </div>
              </div>
              </section>
            </div>
          




           
  );
}

export default AddUser;
