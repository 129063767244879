import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';


const EditUser = () => {
    //get id value
    const {id} = useParams();
    console.log(id);
    const [titlecourse, setAdvice] = useState("");
    const [listshow, setlinklist] = useState("");
    console.log(titlecourse);

    // where without click fetch data
    //https://howcanfind.com/reactapi/editlistcourse.php
    useEffect(() => {          
        const url = `https://howcanfind.com/reactapi/editlistcourse.php?cid=${id}`;
        // const url = `https://www.shriramedu.com/jasonfile/wherecond.php`;   

        const fetchData = async () => {
            try {
                const response = await fetch(url);
                const json = await response.json();
                console.log(json.data.titlecourse); 
                setAdvice(json.data.titlecourse);
                setlinklist(json.data.listshow);
            } catch (error) {
                console.log("error", error);
            }
        };
        fetchData();
    }, []);
    // where without click fetch data

    // update onclick

    const onSubmit = async (event) => {
        event.preventDefault();
        console.log(titlecourse);
        console.log(id);
        //const data = this.state.titlecourse;
        //const dataq = 11;

        // https://howcanfind.com/reactapi/updatelistcourse.php

        await fetch("https://howcanfind.com/reactapi/updatelistcourse.php",{
            method: 'POST',
            headers: new Headers({
                       'Content-Type': 'application/x-www-form-urlencoded',
              }),
              body: new URLSearchParams({
                titlecourse:titlecourse,
                id:id,
                //id:'1',
                //listshow:'tt'
                listshow:listshow
              })
             // right= body: "titlecourse=sassa&listshow=jhjhg"
           // right= body: "titlecourse= " + data
          // body:{
            //   "titlecourse":this.state.titlecourse             
           //}

      
        }).then((res)=>console.log(res));

        // after submit emtey
      //  settitlecourse("")
    }

    // update

    return (
       
           
           <div className="container">
      <h1>Edit User {titlecourse}    
      </h1>     
      <form onSubmit={e=>onSubmit(e)}>
<div>
  <div class="row mb-3">
    <label for="inputEmail3" class="col-sm-2 col-form-label">titlecourse</label>
  
    <div class="col-sm-10">
      <input type="text" class="form-control"
      name = "titlecourse"      
      value={titlecourse}
      onChange={(e)=>{setAdvice(e.target.value)}}
     />
    </div>

    <div class="col-sm-10">
      <input type="text" class="form-control"
      name = "id"      
      value={id}      
     />
    </div>


    <div class="col-sm-10">
      <input type="text" class="form-control"
      name = "listshow"      
      value={listshow}
      onChange={(e)=>{setlinklist(e.target.value)}}
     />
    </div>

    
  </div>
 
 
  </div>
 
  <button type="submit" class="btn btn-primary">Sign in</button>
</form>

    </div>

    );
};

export default EditUser;