import React,{useState} from 'react';
import { useNavigate } from 'react-router-dom';
//import './App.css';
const Contact=()=>{

  const history = useNavigate();
  const[username,setusername]=useState("");
  const[password,setpassword]=useState("");   

  const onSubmit = async (event) => {
    event.preventDefault();
    console.log(username);
    //const data = this.state.titlecourse;
    //const dataq = 11;

    //https://howcanfind.com/reactapi/addlistcourse.php  

    await fetch("https://howcanfind.com/reactapi/loginset.php",{
        method: 'POST',
        headers: new Headers({
                   'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: new URLSearchParams({
            username:username,
            //listshow:'tt'
            password:password
          })
         // right= body: "titlecourse=sassa&listshow=jhjhg"
       // right= body: "titlecourse= " + data
      // body:{
        //   "titlecourse":this.state.titlecourse             
       //}

  
   // }).then((res)=>console.log(res));

  }).then(response => response.json())
  .then((resp) => {
    // jsonData is parsed json object received from url
    console.log(resp);
    console.log(resp.data.titlecourse); 
    //console.log(resp.data.id); 

     const userid = resp.data.id;
     const usernamelogin = resp.data.titlecourse;
     console.log(userid);

    localStorage.setItem("loginidlsset", userid);
    localStorage.setItem("loginnamelsset", usernamelogin);

    const getapiuseridls = localStorage.setItem("loginidlsset", userid);
    
     if(getapiuseridls === ''){
      console.log("not login");
     } else {
      history('/about')
     }


  })

    // after submit emtey
    setusername("")
    setpassword("")
    //history('/')

}

  return (




<div>

<section className="bg-light py-3 py-md-5">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
        <div className="card border border-light-subtle rounded-3 shadow-sm">
          <div className="card-body p-3 p-md-4 p-xl-5">            
                  
                  <h1>Login User</h1>
                  <form onSubmit={e=>onSubmit(e)}>
                  <div className="row mb-3">
                 
                  <div class="col-sm-10">
                  <input type="text" className="form-control"
                  name = "username" placeholder="User name"
                  value={username}
                  onChange={(e)=>{setusername(e.target.value)}}
                  />
                  </div>
                  </div>
                  <div className="row mb-3">
                 
                  <div className="col-sm-10">
                  <input type="text" className="form-control"
                  name = "password" placeholder="Password"
                  value={password}      
                  onChange={(e)=>{setpassword(e.target.value)}}
                  />
                  </div>
                  </div>


                  <button type="submit" className="btn btn-primary">Sign in</button>
                  </form>

                  
            </div>
          </div>          
        </div>        
      </div>
    </div>
</section>







    
  </div>
  );
}

export default Contact;
