import React from 'react';
//import './App.css';
const NotFound=()=>{
//function Home() {
  return (
    <div className="container">
      <h1>hello Link</h1>
    </div>
  );
}

export default NotFound;
