import React, {useEffect, useState} from "react";
import { Link } from 'react-router-dom';
import DataTable from "react-data-table-component";



const Home = () =>{

  const myStyle = {   
    color: 'white',
    // border: '2px solid black',
  }

  
  
  return (     

    

    <div>   
        <div className="contacontainer-fluidiner">     
            <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={require('../images/slider-03.PNG')} className="d-block w-100" alt="..."></img>
                  
                </div>
                <div className="carousel-item">
                  <img src={require('../images/slider-02.PNG')} className="d-block w-100" alt="..."></img>
                </div>
                <div className="carousel-item">
                  <img src={require('../images/slider-03.PNG')} className="d-block w-100" alt="..."></img>
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div> 
        </div>

        <section class="py-5 py-xl-8 bsb-section-py-xxl-1">
      <div class="container mb-5 mb-md-6 mb-xl-10">
        <div class="row justify-content-md-center">
          <div class="col-12 col-md-10 col-lg-9 col-xl-8 col-xxl-7 text-center">
            <h3 class="fs-6 text-secondary mb-2 text-uppercase">What We Do?</h3>
            <h2 class="display-4 fw-bold mb-0">We are proud of Our Best Features.</h2>
          </div>
        </div>
      </div>
      </section>


      

           

            <div className="container">
                <div className=" row align-items-start">

              <div className="card m-2" style={{width:'22rem'}}>
                
                <img src={require('../images/slider-03.PNG')} className="d-block w-30 p-2" alt="..."></img>
                <div className="card-body">
                  <h5 className="card-title">Check out Call Enquiry History</h5>
                  <p className="card-text">Track every Enquiry on your Smartphone with Mobile-Friendly User Interface. Easily Create, Schedule, and Join Meetings</p>
                  
                </div>
              </div>

              <div className="card m-2" style={{width:'23rem'}}>
                
                <img src={require('../images/slider-01.PNG')} className="d-block w-30 p-2" alt="..."></img>
                <div className="card-body">
                  <h5 className="card-title">Follow-up Tracking</h5>
                  <p className="card-text"> The biggest Fear for Follow-ups is missing them out. 
                  Get Alerts and Reminders to ensure you follow up on your enquiries on time.</p>
                  
                </div>
              </div>

              <div className="card m-2" style={{width:'23rem'}}>
                
                <img src={require('../images/slider-02.PNG')} className="d-block w-30 p-2" alt="..."></img>
                <div className="card-body">
                  <h5 className="card-title">Search and Filter Enquiry</h5>
                  <p className="card-text"> With Search and Filter functionality, 
                  save your valuable time to locate specific enquiry and channelize it to productive tasks..</p>
                 
                </div>
              </div>

              

                 </div>
             </div>





        


              {/* new design */}
              
    <section className="bg-primary" data-bs-theme="dark">
      <div className="container-fluid overflow-hidden my-5">
        <div className="row">
          <div className="col-12 col-md-6 p-0">
            <img className="img-fluid w-100 h-100 object-fit-cover" loading="lazy" src={require('../images/call-Capture.PNG')} alt=""></img>
          </div>
          <div className="col-12 col-md-6 align-self-md-end">
            <div className="row py-3 py-sm-5 py-xxl-9 bsb-section-mt-md-1 justify-content-sm-center">
              <div className="col-12 col-sm-10">
                <h2 style={myStyle} className="display-4 fw-bold mb-4">Organize Better, Manage Faster</h2>
                <p style={myStyle} className=" mb-5 ">Choose the Best Call Management Software.</p>
                <div className="d-flex mb-4">
                  <div>
                    <span className="btn btn-accent bsb-btn-circle pe-none me-4">1</span>
                  </div>
                  <div>
                    <h4 style={myStyle} className="fw-bold mb-3">Business hours</h4>
                    <p style={myStyle} className="mb-0">  Set customized schedules to confirm when each of your numbers is available to receive calls.</p>
                  </div>
                </div>
                <div style={myStyle} className="d-flex mb-4">
                  <div>
                    <span className="btn btn-accent bsb-btn-circle pe-none me-4">2</span>
                  </div>
                  <div>
                    <h4  className="fw-bold mb-3">Call routing</h4> 
                    <p className="mb-0"> Direct calls to the correct teammates every time by customizing distribution and ring rules</p>
                  </div>
                </div>
                <div style={myStyle} className="d-flex">
                  <div>
                    <span className="btn btn-accent bsb-btn-circle pe-none me-4">3</span>
                  </div>
                  <div>
                    <h4 className="fw-bold mb-3">Lower costs</h4>
                    <p className="mb-0">  Call management software increases your efficiency and minimizes the need for large teams..</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    

               {/* footter */}





               <div className="container" style={{ textAlign:"center"}}>
                
                <h2 class="display-4 fw-bold mb-0">About Us</h2>
          </div>

            <div className="container py-5">

                      <div className="row gy-4 mb-4">

                        <div className="col-lg-6 content aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                          <p>
                          Call Management Software is the most elegant and efficient solution for your sales team to manage enquiries.
                           It helps you track the status of all your deals
                          </p>
                          <ul>
                            <li><i className="bi bi-check2-circle"></i> <span> Ontime Response to Enquiries .</span></li>
                            <li><i className="bi bi-check2-circle"></i> <span> Schedule a Follow-up Response.</span></li>
                            <li><i className="bi bi-check2-circle"></i> <span> Track and Re-assign Call Enquiries</span></li>
                          </ul>
                        </div>

                        <div className="col-lg-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                          <p>This system makes sure that the enquiries are carefully registered, tracked, and handled so that 
                            sales teams can respond. The Call Management System is a solution that was created to solve 
                            the problems that many organizations have in keeping track of their call inquiries or leads from 
                            their client. The fundamental issue is how these businesses handle these call inquiries in real-time </p>
                            <a href="#" className="btn btn-primary">Go somewhere</a>
                        </div>

                      </div>

            </div>


          <footer id="footer" style={myStyle}  className="footer bg-primary" >

      <div className="container footer-top mt-4">
            <div className="row gy-4">
              <div className="col-lg-4 col-md-4 footer-about">
                  <h4>Solutions</h4> 
                    <ul>
                      <li><i className="bi bi-chevron-right"></i> Facility Management</li>
                      <li><i className="bi bi-chevron-right"></i> Asset Management</li>
                      <li><i className="bi bi-chevron-right"></i> Complaint Management</li>
                      <li><i className="bi bi-chevron-right"></i> Permit to Work Management</li>
                      <li><i className="bi bi-chevron-right"></i> Budget & Expense</li>
                    </ul>
              </div>

              

              <div className="col-lg-4 col-md-3 footer-links">
                <h4>Industries We Serve</h4>
                <ul>
                  <li><i className="bi bi-chevron-right"></i> Education</li>
                  <li><i className="bi bi-chevron-right"></i> Real Estate</li>
                  <li><i className="bi bi-chevron-right"></i> Healthcare</li>
                  <li><i className="bi bi-chevron-right"></i> Hotels</li>
                  <li><i className="bi bi-chevron-right"></i> Food & Beverage</li>
                </ul>
              </div>

              <div className="col-lg-4 col-md-12">
                <h4>Track your call Enquiry</h4>
                <p> Track and reassign enquiries with comprehensive follow-up history.
                  </p>
                
              </div>

            </div>
          </div>



          </footer>

    </div>    
  );
};
export default Home;
