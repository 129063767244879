import React from 'react';
import { Link, NavLink } from 'react-router-dom';
//import './App.css';
const Navbar=()=>{
//function Home() {
  return (
    <div className="contacontainer-fluidiner">
      
      <nav className="navbar navbar-expand-lg  navbar-dark bg-dark">
  <div className="container-fluid">
    <a className="navbar-brand" href="#">MyShort</a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNavDropdown">
      <ul className="navbar-nav ms-auto">
        <li className="nav-item">
          <NavLink className="nav-link"  exact to="/">Home</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link"  exact to="/contactlist">Contact List</NavLink>
        </li>
        
        <li className="nav-item">
          <NavLink className="nav-link" exact to="/userlist/AddUser">Adduser</NavLink>
        </li>     
        <li className="nav-item"> 
          <NavLink className="nav-link" exact to="/about">About</NavLink>
        </li> 

        <li className="nav-item">
          <NavLink className="nav-link" exact to="/contact">Login</NavLink>
        </li>      
      </ul>
    </div>
  </div>
</nav>



    </div>
  );
}

export default Navbar;
