import logo from './logo.svg';
import './App.css';
import './dataTables.bootstrap5.css';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../node_modules/bootstrap/dist/js/bootstrap.js';
import Home from './components/pages/Home';   
import Contactlist from './components/pages/Contactlist';
import About from './components/pages/About';
import Contact from './components/pages/Contact';
import Navbar from './components/layout/Navbar';
import { BrowserRouter as Router,Routes, Route, Link } from "react-router-dom";
import NotFound from './components/pages/NotFound';
import AddUser from './components/userlist/AddUser';
import EditUser from './components/userlist/EditUser';



function App() {
  return (
    <Router> 
        <Navbar/>      
      <Routes>
      <Route exact path="/" element={ <Home />} /> 
<Route exact  path="/contactlist" element={ <Contactlist />}/>
<Route exact  path="/about" element={ <About />}/>
<Route exact  path="/contact" element={ <Contact />}/> 
<Route exact  path="/userlist/AddUser" element={ <AddUser />}/>
<Route exact  path="/userlist/EditUser/:id"  element={ <EditUser />}/>
<Route component={NotFound}/>
</Routes>      
    </Router>
  );
}

export default App;
